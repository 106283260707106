@import "bootstrapCustom";

@font-face {
  font-family: "FuturisRegular";
  src: local("FuturisRegular"), url(../fonts/Futuris.ttf) format("truetype");
}

@font-face {
  font-family: "AdelleRegular";
  src: local("AdelleRegular"), url(../fonts/Adelle-Reg.woff) format("woff");
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
td,
.footer-font,
span {
  font-family: "FuturisRegular";
}

p,
li,
a {
  font-family: "AdelleRegular";
}

td {
  border: none;
  margin: 10em;
}

tr {
  margin-bottom: 10rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

td {
  vertical-align: middle;
  font-size: 1.2em;
}

.carousel-indicators {
  display: none;
}

.carousel-item img {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 0.25rem;
}

.filigree-header {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.footer-adjust {
  @media (min-width: 1350px) {
    max-width: 80%;
  }
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.nav-link {
  font-size: 1.2em;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown {
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-toggle::after {
  display: none;
}

.responsiveConstruction {
  font-size: 1.75rem;

  @media (min-width: 767px) {
    font-size: 3rem;
  }
}

.fourohfour {
  background-image: url(../public/lost.gif);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  color: white;

  h1 {
    font-size: 10rem;
  }

  @media (min-width: 767px) {
    h1 {
      font-size: 20rem;
    }
  }
}

.overlay {
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100vh;
}

.card {
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.aftercare-section {
  h2,h3,h4,h5,h6 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    padding: 0.75em;
    margin: 0;
    color: #0c63e4;
    background-color: #e7f1ff;

    
  }

  p {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1em;
    margin: 0;

    border-top: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 2em;
    border-radius: 0.25rem;
  }
  li {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: none;

    width: 100%; // For `<button>`s (anchors become 100% by default though)
    color: $list-group-action-color;
    text-align: inherit; // For `<button>`s (anchors inherit)

    // Hover state
    &:hover,
    &:focus {
      z-index: 1; // Place hover/focus items above their siblings for proper border styling
      color: $list-group-action-hover-color;
      text-decoration: none;
      background-color: $list-group-hover-bg;
    }

    &:active {
      color: $list-group-action-active-color;
      background-color: $list-group-action-active-bg;
    }
  }
}
